import React from "react"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import * as Icon from "react-feather"
import { graphql, Link } from "gatsby"

import NavbarNew from "../../components/NavbarNew"
import GetInTouch from "../../components/get-in-touch"

const Blog = ({ data }) => {
  // console.log("data list", data)
  const dataList = data?.blogDetails?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  return (
    <Layout>
      <SEO
        title="Blogs"
        description="Tarkashilpa is a web and mobile application development company with knowledge of latest technologies and innovation. We follow strict app development standards."
        keywords="Web Application Development Services Company Mobile Application Development Services Company"
        url={`/blogs`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <PageBanner pageTitle="Blogs" />

      <div className="blog-area ptb-80">
        <div className="container">
          <div className="row blog-list">
            {dataList &&
              dataList.length > 0 &&
              dataList.map((list: any, index: number) => (
                <div className="col-lg-4 col-md-6" key={index + 1}>
                  <div className="single-blog-post">
                    <div className="blog-image">
                      <Link to={list?.childMdx?.slug}>
                        <img
                          src={
                            list?.childMdx?.frontmatter?.thumbImage?.publicURL
                          }
                          alt="image"
                        />
                      </Link>

                      {/* <div className="date">
                  <Icon.Calendar /> March 15, 2021
                </div> */}
                    </div>

                    <div className="blog-post-content">
                      <h3>
                        <Link to={list?.childMdx?.slug}>
                          {list?.childMdx?.frontmatter?.title}
                        </Link>
                      </h3>

                      <p>{list?.childMdx?.frontmatter?.summary}</p>

                      <Link to={list?.childMdx?.slug} className="read-more-btn">
                        Read More <Icon.ArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

            {/* Pagination */}
            {/* <div className="col-lg-12 col-md-12">
          //   <div className="pagination-area">
          //     <nav aria-label="Page navigation">
          //       <ul className="pagination justify-content-center">
          //         <li className="page-item">
          //           <Link to="#" className="page-link">
          //             Prev
          //           </Link>
          //         </li>

          //         <li className="page-item active">
          //           <Link to="#" className="page-link">
          //             1
          //           </Link>
          //         </li>

          //         <li className="page-item">
          //           <Link to="#" className="page-link">
          //             2
          //           </Link>
          //         </li>

          //         <li className="page-item">
          //           <Link to="#" className="page-link">
          //             3
          //           </Link>
          //         </li>

          //         <li className="page-item">
          //           <Link to="#" className="page-link">
          //             Next
          //           </Link>
          //         </li>
          //       </ul>
          //     </nav>
          //   </div>
          // </div> */}
          </div>
        </div>
      </div>
      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default Blog
export const query = graphql`
  query blog {
    blogDetails: allFile(
      filter: {
        sourceInstanceName: { eq: "blogDetails" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            thumbImage {
              publicURL
            }
            summary
            order
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
  }
`
